<template>
<div class="g-3 p-5">
<p class="mb-5 fs-2">{{event.event_name}} Registration Form</p>
<hr />

<div v-if="event.result == 'failed'">
    <div class="alert alert-danger">{{event.message}}</div>
</div>
<div v-else>
    <div v-if="event.status == 'test'">
        <div class="alert alert-warning">The registration process is under maintenance. Please do not submit the form.</div>
    </div>

    <div v-if="result.result == 'failed'" class="alert alert-danger">Failed to register. Error: {{ result.message }}</div>
    <div v-else class="">{{ result.message }}</div>

    <div v-if="result.result == 'done'">
        <p class="fs-4">Thank you for registering for {{event.event_name}}. You will receive an email confirmation after approval.</p>
        <p class="fs-4">Should you have any questions, please contact us at <a href="mailto:events@malabs.com">events@malabs.com</a></p>

        <!-- <div v-if="result.subscribe.status =='failed'" class="alert alert-danger">{{result.subscribe.message}}</div> -->
    </div>


    <div v-if="result.result == '' || result.result == 'failed'">

        <form action="" class="row">

            <div class="col-md-6">
                <label for="first_name" class="form-label" :class="{'text-danger': data.first_name.error}">First Name</label>
                <input type="text" class="form-control" id="first_name" v-model="data.first_name.input" required>
            </div>
            <div class="col-md-6">
                <label for="last_name" class="form-label" :class="{'text-danger': data.last_name.error}">Last Name</label>
                <input type="text" class="form-control" id="last_name" v-model="data.last_name.input" required>
            </div>
            <div class="col-md-6">
                <label for="email" class="form-label" :class="{'text-danger': data.email.error}">Email Address</label>
                <input type="email" class="form-control" id="email" v-model="data.email.input" required>
            </div>
            <div class="col-md-6">
                <label for="position" class="form-label" :class="{'text-danger': data.position.error}">Position</label>
                <select class="form-control" id="position" v-model="data.position.input" required @change="handlePosition">
                    <option value=""></option>
                    <option value="CEO/President/General Manager">CEO</option>
                    <option value="VP of Sales">VP of Sales</option>
                    <option value="VP of Marketing">VP of Marketing</option>
                    <option value="Product Manager">Product Manager</option>
                    <option value="Purchasing Manager">Purchasing Manager</option>
                    <option value="Product/Marketing Manager">Marketing Manager</option>
                    <option value="Sales Manager">Sales Manager</option>
                    <option value="Account Executive">Account Executive</option>
                    <option value="Technical Support">Technical Support</option>
                    <option value="Others">Others</option>
                </select>
                <div v-if="show.position_other" class="mt-2">
                    <label for="position_other" class="form-label" :class="{'text-danger': data.position_other.error}">Please specify</label>
                    <input type="text" class="form-control" id="position_other" v-model="data.position_other.input" required>
                </div>
            </div>
            <div class="col-md-4">
                <label for="company" class="form-label" :class="{'text-danger': data.company.error}">Company Name</label>
                <input type="text" class="form-control" id="company" v-model="data.company.input" required>
            </div>
            <div class="col-md-4">
                <label for="business" class="form-label" :class="{'text-danger': data.business.error}">Company Category</label>
                <select class="form-control" id="business" v-model="data.business.input" required @change="handleBusiness">
                    <option value=""></option>
                    <option value="Direct Market Reseller">Direct Market Reseller</option>
                    <option value="Distributor">Distributor</option>
                    <option value="E-Tailer">E-Tailer</option>
                    <option value="Manufacturer">Manufacturer</option>
                    <option value="OEM">OEM</option>
                    <option value="Reseller/Broker">Reseller</option>
                    <option value="Retail">Retail</option>
                    <option value="System Builder">System Builder</option>
                    <option value="VAR">VAR</option>
                    <option value="Others">Others</option>
                </select>
                <div v-if="show.business_other" class="mt-2">
                    <label for="business_other" class="form-label" :class="{'text-danger': data.business_other.error}">Please specify</label>
                    <input type="text" class="form-control" id="business_other" v-model="data.business_other.input" required>
                </div>
            </div>
            <div class="col-md-4">
                <label for="website" class="form-label">Company Website <span class="fw-normal">(Optional)</span></label>
                <input type="text" class="form-control" id="website" v-model="data.website.input">
            </div>
            <div class="col-md-3">
                <label for="country" class="form-label" :class="{'text-danger': data.country.error}">Country</label>
                <select class="form-control" id="country" v-model="data.country.input" required @change="handleCountry">
                    <select-option-list-country />
                </select> 
            </div>
            <div class="col-md-3">
                <label for="city" class="form-label" :class="{'text-danger': data.city.error}">City</label>
                <input type="text" class="form-control" id="city" v-model="data.city.input" required>
            </div>
            <div class="col-md-3">
                <label for="state" class="form-label" :class="{'text-danger': data.state.error}">State</label>
                <select v-if="show.state" class="form-control" id="state" v-model="data.state.input">
                    <select-option-list-state />
                </select> 
                <input v-if="show.state_other" type="text" class="form-control" id="state_other" v-model="data.state_other.input">
            </div>
            <div class="col-md-3">
                <label for="zip_code" class="form-label" :class="{'text-danger': data.zip.error}">Zip Code</label>
                <input type="text" class="form-control" id="zip" v-model="data.zip.input" required>
            </div>
            <div class="col-md-4">
                <label for="is_customer" class="form-label" :class="{'text-danger': data.is_customer.error}">Are you a Ma Labs Customer?</label>
                <select class="form-control" id="is_customer" v-model="data.is_customer.input" required @change="handleIsCustomer">
                    <option value=""></option>
                    <option value="Yes">Yes</option>
                    <option value="Event Staff">No, Event Staff</option>
                    <option value="Exhibitor">No, Exhibitor</option>
                    <option value="Malabs Employee">No, Ma Labs employee</option>
                </select> 
            </div>
            <div class="col-md-4">
                <div v-if="show.customer_id">
                    <label for="customer_id" class="form-label" :class="{'text-danger': data.customer_id.error}">Customer ID</label>
                    <input type="text" class="form-control" id="customer_id" v-model="data.customer_id.input" required>   
                </div>
            </div>
            <div class="col-md-4">
                <div v-if="show.sales">
                    <label for="sales" class="form-label" :class="{'text-danger': data.sales.error}">Ma Labs Account Manager</label>
                    <input type="text" class="form-control" id="sales" v-model="data.sales.input" required>
                </div>
            </div>
            <!-- <div class="col-md-12">
                <label for="event" class="form-label" :class="{'text-danger': data.event.error}">Do you plan to attend the entire event?</label>
                <div class="form-controls" id="event">
                    <span class="pe-4"><input type="radio" name="eventoptions" value="seminar" v-model="data.event.input"> Morning Seminars Only </span>
                    <span class="pe-4"><input type="radio" name="eventoptions" value="event" v-model="data.event.input"> Afternoon Exhibition Only </span>
                    <span><input type="radio" name="eventoptions" value="both" v-model=".event.input" checked="checked"> Both (Seminars + Exhibition)</span>
                </div>
            </div> -->
            <div class="col-md-12 mt-4">
                <input type="checkbox" id="terms" v-model="data.terms.input" required> <span class="fw-bold" :class="{'text-danger': data.terms.error}">Terms and Conditions</span>
                <div class="ps-3">
                    By checking this box, you agree to our privacy policy and consent to the collection and use of your information solely for this event. Please review our Ma Labs Privacy Policy: <a href="https://www.malabs.com/company/privacy-policy" target="_blank">https://www.malabs.com/company/privacy-policy</a>
                </div>
            </div>
            <div class="col-md-12 mt-4">
                <input type="checkbox" id="subscribe" v-model="data.subscribe.input"> <span class="fw-bold">Subscribe to Ma Labs Newsletter to receive updates</span> (Optional)
            </div> 

            <div class="col-12 mt-5">
                <button type="button"
                    v-if="waiting"
                    class="btn btn-primary"
                    disabled
                    >
                    <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Submit
                    </button>
                    <button
                    v-if="!waiting"
                    class="btn btn-primary btn-lg"
                    id="cart_btn"
                    @click="handleSubmit"
                    >
                    Submit
                    </button>
            </div>

            
        </form>
    </div>
</div>

</div>
</template>

<script>
import SelectOptionListCountry from "../../components/SelectOptionListCountry.vue";
import SelectOptionListState from "../../components/SelectOptionListState.vue";
import { ref, onMounted } from 'vue'
import { getAPI } from '../../utils/axios-api'
import $ from 'jquery'

export default {
    name: "TechFairRegister",
    components: { SelectOptionListCountry, SelectOptionListState },
    props: ["slug"],
    setup(props, context) {
        const event = ref({result: '', event_name: '', status: '', message: ''})
        const result = ref({result: '', status: '', message: ''})
        const waiting = ref(false)

        const data = ref({
        first_name: {input: null, error: false},
        last_name: {input: null, error: false},
        email: {input: null, error: false, message: null},
        position: {input: null, error: false},
        position_other: {input: null, error: false},
        company: {input: null, error: false},
        business: {input: null, error: false},
        business_other: {input: null, error: false},
        website: {input: null, error: false},
        country: {input: null,  error: false},
        city: {input: null, error: false},
        state: {input: null, error: false},
        state_other: {input: null, error: false},
        zip: {input: null, error: false},
        is_customer: {input: null, error: false},
        customer_id: {input: null, error: false},
        sales: {input: null, error: false},
        event: {input: 'event', error: false},
        terms: {input: null, error: false},
        subscribe: {input: null, error: false},
        })

        const show = ref({
        position_other: false,
        business_other: false,
        state: true,
        state_other: false,
        customer_id: false,
        sales: false
        })

        //call api to get the event name
        getAPI
        .get('/marketing/techfair/event/' + props.slug + "/")
        .then(response => {
            event.value["result"] = "done"
            event.value["event_name"] = response.data["data"]['event_name']
            event.value["status"] = response.data["status"]
            event.value["message"] = ""
        })
        .catch(err => {
            try {
                event.value["result"] = 'failed'
                event.value["event_name"] = err.response.data["data"]['event_name']
                event.value["status"] = err.response.data["status"]
                event.value["message"] = err.response.data["message"]
            }
            catch {
                event.value["result"] = 'failed'
                event.value["event_name"] = err.response.data["data"]['event_name']
                event.value["status"] = 'error'
                event.value["message"] = "Something is wrong. Failed to register."
            }
        })

        const handlePosition = (e) => {
            if (data.value.position.input == 'Others') {
                show.value.position_other = true
            }
            else show.value.position_other = false
        }

        const handleBusiness = () => {
            if (data.value.business.input == 'Others') show.value.business_other = true
            else show.value.business_other = false
        }

        const handleCountry = () => {
            if (data.value.country.input == 'US') {
                show.value.state = true
                show.value.state_other = false
            }
            else {
                show.value.state = false
                show.value.state_other = true
            }
        }

        const handleIsCustomer = () => {
            if (data.value.is_customer.input == 'Yes') {
                show.value.customer_id = true
                show.value.sales = true
            }
            else {
                show.value.customer_id = false
                show.value.sales = false
            }
        }

        function validate() {
            let valid = true
            if (data.value.first_name.input != "" && data.value.first_name.input != null) {
                data.value.first_name.error = false
                $("#first_name").removeClass("red_border")
            }
            else {
                data.value.first_name.error = true
                $("#first_name").addClass("red_border")
                valid = false
            }
            if (data.value.last_name.input != "" && data.value.last_name.input != null) {
                data.value.last_name.error = false
                $("#last_name").removeClass("red_border")
            }
            else {
                data.value.last_name.error = true
                $("#last_name").addClass("red_border")
                valid = false
            }
            if (data.value.email.input != "" && data.value.email.input != null) {
                data.value.email.error = false
                $("#email").removeClass("red_border")
            }
            else {
                data.value.email.error = true
                $("#email").addClass("red_border")
                valid = false
            }
            if (data.value.position.input != "" && data.value.position.input != null) {
                data.value.position.error = false
                $("#position").removeClass("red_border")
                if (data.value.position.input == "Others"){
                    if (data.value.position_other.input != "" && data.value.position_other.input != null) {
                        data.value.position_other.error = false
                        $("#position_other").removeClass("red_border")
                    }
                    else {
                        data.value.position_other.error = true
                        $("#position_other").addClass("red_border")
                        valid = false
                    }
                }
            }
            else {
                data.value.position.error = true
                $("#position").addClass("red_border")
                valid = false
            }
            if (data.value.company.input != "" && data.value.company.input != null) {
                data.value.company.error = false
                $("#company").removeClass("red_border")
            }
            else {
                data.value.company.error = true
                $("#company").addClass("red_border")
                valid = false
            }
            if (data.value.business.input != "" && data.value.business.input != null) {
                data.value.business.error = false
                $("#business").removeClass("red_border")
                if (data.value.business.input == "Others"){
                    if (data.value.business_other.input != "" && data.value.business_other.input != null) {
                        data.value.business_other.error = false
                        $("#business_other").removeClass("red_border")
                    }
                    else {
                        data.value.business_other.error = true
                        $("#business_other").addClass("red_border")
                        valid = false
                    }
                }
            }
            else {
                data.value.business.error = true
                $("#business").addClass("red_border")
                valid = false
            }
            if (data.value.country.input != "" && data.value.country.input != null) {
                data.value.country.error = false
                $("#country").removeClass("red_border")
            }
            else {
                data.value.country.error = true
                $("#country").addClass("red_border")
                valid = false
            }
            if (data.value.city.input != "" && data.value.city.input != null) {
                data.value.city.error = false
                $("#city").removeClass("red_border")
            }
            else {
                data.value.city.error = true
                $("#city").addClass("red_border")
                valid = false
            }
            if (data.value.state.input != "" && data.value.state.input != null) {
                data.value.state.error = false
                $("#state").removeClass("red_border")
                if (data.value.country.input != "US"){
                    if (data.value.state_other.input != "" && data.value.state_other.input != null) {
                        data.value.state.error = false
                        $("#state_other").removeClass("red_border")
                    }
                    else {
                        data.value.state.error = true
                        $("#state_other").addClass("red_border")
                        valid = false
                    }
                }
            }
            else {
                data.value.state.error = true
                $("#state").addClass("red_border")
                valid = false
            }
            if (data.value.zip.input != "" && data.value.zip.input != null) {
                data.value.zip.error = false
                $("#zip").removeClass("red_border")
            }
            else {
                data.value.zip.error = true
                $("#zip").addClass("red_border")
                valid = false
            }
            //customer
            if (data.value.is_customer.input != "" && data.value.is_customer.input != null) {
                data.value.is_customer.error = false
                $("#is_customer").removeClass("red_border")
                if (data.value.is_customer.input == "Yes"){
                    if (data.value.customer_id.input != "" && data.value.customer_id.input != null) {
                        data.value.customer_id.error = false
                        $("#customer_id").removeClass("red_border")
                    }
                    else {
                        data.value.customer_id.error = true
                        $("#customer_id").addClass("red_border")
                        valid = false
                    }
                    if (data.value.sales.input != "" && data.value.sales.input != null) {
                        data.value.sales.error = false
                        $("#sales").removeClass("red_border")
                    }
                    else {
                        data.value.sales.error = true
                        $("#sales").addClass("red_border")
                        valid = false
                    }
                }
            }
            else {
                data.value.is_customer.error = true
                $("#is_customer").addClass("red_border")
                valid = false
            }
            // //event
            // if (data.value.event.input != "" && data.value.event.input != null) {
            //     data.value.event.error = false
            //     $("#event").removeClass("red_border")
            // }
            // else {
            //     data.value.event.error = true
            //     $("#event").addClass("red_border")
            //     valid = false
            // }
            //terms
            if (data.value.terms.input != "" && data.value.terms.input != null) {
                data.value.terms.error = false
                $("#terms").removeClass("red_border")
            }
            else {
                data.value.terms.error = true
                $("#terms").addClass("red_border")
                valid = false
            }

            return valid
        }

        const handleSubmit = async () => {
            waiting.value = true
            
            if (validate() == true) {
                let payload = {
                    event_slug: props.slug,
                    first_name: data.value.first_name.input,
                    last_name: data.value.last_name.input,
                    email: data.value.email.input,
                    position: data.value.position.input,
                    position_other: data.value.position_other.input,
                    company: data.value.company.input,
                    business: data.value.business.input,
                    business_other: data.value.business_other.input,
                    website: data.value.website.input,
                    country: data.value.country.input,
                    city: data.value.city.input,
                    state: data.value.state.input,
                    state_other: data.value.state_other.input,
                    zip: data.value.zip.input,
                    is_customer: data.value.is_customer.input,
                    customer_id: data.value.customer_id.input,
                    sales: data.value.sales.input,
                    event: data.value.event.input,
                    subscribe: data.value.subscribe.input,
                }
                

                //call api to process the form
                getAPI
                .post('/marketing/techfair/register/', payload)
                .then(response => {
          
                    //show result
                    result.value["result"] = 'done'
                    result.value["status"] = response.data["status"]
                    result.value["subscribe"] = response.data["subscribe"]
                    result.value["message"] = ""
                    window.scrollTo(0,0);

                    waiting.value = false
                })
                .catch(err => {
   
                    try {
                        result.value["result"] = 'failed'
                        result.value["status"] = err.response.data["status"]
                        result.value["message"] = err.response.data["message"]
                    }
                    catch {
                        result.value["result"] = 'failed'
                        result.value["status"] = "error"
                        result.value["message"] = "Something is wrong. Failed to register."
                    }
                    window.scrollTo(0,0);

                    waiting.value = false
                })

            }
            else {
    
                waiting.value = false
            }

        }

        return {
            data, show, 
            event, result, waiting,
            handlePosition, handleBusiness, handleCountry, handleIsCustomer,
            handleSubmit
        }
    }
}
</script>

<style scoped>
form {
    width:97%;
}
.hide {display: none;}
label {font-weight: bold; padding-top:20px;}
.red_border {border-color: red !important;}
</style>